const data = [
    {
      year: 2019,
      items: [
        {
          title: 'Категория «Мастер ногтевого сервиса»',
          name: 'Инга Аванесян',
          country: 'Украина',
          name2: 'Маргарита Чернышова',
          country2: 'Беларусь',
        },
        {
          title: 'Категория «Парикмахер»',
          name: 'Анастасия Михалюк',
          country: 'Россия',
          name2: 'Катерина Радевич',
          country2: 'Украина',
        },
        {
          title: 'Категория «Make-up артисты» ',
          name: 'Анна Смирнова',
          country: 'Россия',
          name2: 'Елена Петрова',
          country2: 'Беларусь',
        },
        {
          title: 'Категория «Косметолог»',
          name: 'Светлана Андреева',
          country: 'Молдова',
          name2: 'Ольга Лунина',
          country2: 'Россия',
        },
        {
          title: 'Категория «Мастер перманентного макияжа»',
          name: 'Анна Игнатенко',
          country: 'Россия',
          name2: 'Дарья Махиня',
          country2: 'Украина',
        },
        {
          title: 'Категория «Тату мастер»',
          name: 'Елена Иванова',
          country: 'Россия',
          name2: 'Мария Потапова',
          country2: 'Беларусь',
        },

      ],
    },
    {
        year: 2020,
        items: [
          {
            title: 'Категория «Мастер ногтевого сервиса»',
            name: 'Яна Левченко',
            country: 'Беларусь',
            name2: 'Виктория Тимонина',
            country2: 'Россия',
          },
          {
            title: 'Категория «Парикмахер»',
            name: 'Дарья Бавер',
            country: 'Россия',
            name2: 'Марина Пшеничка',
            country2: 'Молдова',
          },
          {
            title: 'Категория «Make-up артисты» ',
            name: 'Ольга Дмитриева',
            country: 'Россия',
            name2: 'Айгуль Нурланова',
            country2: 'Казахстан',
          },
          {
            title: 'Категория «Косметолог»',
            name: 'Екатерина Алексеева',
            country: 'Россия',
            name2: 'Лилия Анурьева',
            country2: 'Россия',
          },
          {
            title: 'Категория «Мастер перманентного макияжа»',
            name: 'Анастасия Ковязина',
            country: 'Россия',
            name2: 'Алина Гокк',
            country2: 'Украина',
          },
          {
            title: 'Категория «Тату мастер»',
            name: 'Кристина Фетисова',
            country: 'Молдова',
            name2: 'Екатерина Суркова',
            country2: 'Украина',
          },

        ],
      },
      {
        year: 2021,
        items: [
          {
            title: 'Категория «Мастер ногтевого сервиса»',
            name: 'Каролина Имашева',
            country: 'Казахстан',
            name2: 'Маргарита Мясоедова',
            country2: 'Украина',
          },
          {
            title: 'Категория «Парикмахер»',
            name: 'Татьяна Росс',
            country: 'Россия',
            name2: 'Лолита Лукина',
            country2: 'Казахстан',
          },
          {
            title: 'Категория «Make-up артисты» ',
            name: 'Татьяна Соколова',
            country: 'Россия',
            name2: 'Виктория Семенова',
            country2: 'Украина',
          },
          {
            title: 'Категория «Косметолог»',
            name: 'Елена Богданова',
            country: 'Россия',
            name2: 'Олеся Новичкова',
            country2: 'Россия',
          },
          {
            title: 'Категория «Мастер перманентного макияжа»',
            name: 'Рита Ризванова',
            country: 'Россия',
            name2: 'Катерина Саенко',
            country2: 'Украина',
          },
          {
            title: 'Категория «Тату мастер»',
            name: 'Аманита Мускария',
            country: 'Казахстан',
            name2: 'Полина Козарезова',
            country2: 'Россия',
          },

        ],
      },
      {
        year: 2022,
        items: [
          {
            title: 'Категория «Мастер ногтевого сервиса»',
            name: 'Елизавета Лютер',
            country: 'Молдова',
            name2: 'Дарья Письменная',
            country2: 'Россия',
          },
          {
            title: 'Категория «Парикмахер»',
            name: 'Анна Ширяева',
            country: 'Украина',
            name2: 'Дарья Евстратова',
            country2: 'Казахстан',
          },
          {
            title: 'Категория «Make-up артисты» ',
            name: 'Наталья Васильева',
            country: 'Россия',
            name2: 'Алина Жукова',
            country2: 'Казахстан',
          },
          {
            title: 'Категория «Косметолог»',
            name: 'Настя Камыш',
            country: 'Беларусь',
            name2: 'Екатерина Тепло',
            country2: 'Украина',
          },
          {
            title: 'Категория «Мастер перманентного макияжа»',
            name: 'Екатерина Бойко',
            country: 'Украина',
            name2: 'Ксения Квитченко',
            country2: 'Украина',
          },
          {
            title: 'Категория «Тату мастер»',
            name: 'София Андрющенко',
            country: 'Украина',
            name2: 'Юлия Проскурина',
            country2: 'Беларусь',
          },

        ],
      },
      {
        year: 2023,
        items: [
          {
            title: 'Категория «Мастер ногтевого сервиса»',
            name: 'Алина Колокольцова',
            country: 'Молдова',
            name2: 'Олеся Фрайдлин',
            country2: 'Россия',
          },
          {
            title: 'Категория «Парикмахер»',
            name: 'Анастасия Михалюк',
            country: 'Россия',
            name2: 'Катерина Радевич',
            country2: 'Украина',
          },
          {
            title: 'Категория «Make-up артисты» ',
            name: 'Дарья Михайлова',
            country: 'Россия',
            name2: 'Кристина Коваленко',
            country2: 'Украина',
          },
          {
            title: 'Категория «Косметолог»',
            name: 'Стелла Аксенова',
            country: 'Беларусь',
            name2: 'Екатерина Григорьева',
            country2: 'Россия',
          },
          {
            title: 'Категория «Мастер перманентного макияжа»',
            name: 'Екатерина Филатова',
            country: 'Казахстан',
            name2: 'Дарья Волкова',
            country2: 'Украина',
          },
          {
            title: 'Категория «Тату мастер»',
            name: 'Екатерина Бессеребренникова',
            country: 'Россия',
            name2: 'Анна Майорова',
            country2: 'Казахстан',
          },

        ],
      },
  ];

  export default data;
