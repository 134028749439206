import Img2019_1 from '../img/jury/data6 2019_1.jpg'
import Img2019_2 from '../img/jury/data6 2019_2.jpg'
import Img2019_3 from '../img/jury/data6 2019_3.jpg'
import Img2020_1 from '../img/jury/data6 2020_1.jpg'
import Img2020_2 from '../img/jury/data6 2020_2.jpg'
import Img2020_3 from '../img/jury/data6 2020_3.jpg'
import Img2021_1 from '../img/jury/data6 2021_1.jpg'
import Img2021_2 from '../img/jury/data6 2021_2.jpg'
import Img2021_3 from '../img/jury/data6 2021_3.jpg'
import Img2022_1 from '../img/jury/photo_2024-09-25_16-00-11.jpg'
import Img2023_1 from '../img/jury/data6 2023_1.jpg'
import Img2023_2 from '../img/jury/data5 2023_2.jpg'

const data6 = [
    {
      year: 2019,
      items: [
        {
          name: 'Яна Штепа',
          country: 'Украина',
          image: Img2019_1,
          text1: 'Судья международных чемпионатов по визажу.',
          text2: 'Автор и разработчик программ обучения в области макияжа.',
          text3: 'Визажист-стилист, гример, бровист.',
          text4: 'Инструктор-преподаватель и ведущий технолог KODI PROFESSINAL по направлению "Визаж".',
          text5: 'Визажист известных телеканалов и телепроектов.',
        },
        {
            name: 'Jabe',
            country: 'Великобритания',
          image: Img2019_2,
          text1: 'Художник, визажист.',
          text2: 'Работы представлены в таких журналах, как Vogue.it, Vogue Gioiello, Vogue Accessory, Vogue Sposa',
          text3: 'Участвовал в разработке рекламных кампаний, каталогов и нескольких показов мод для таких брендов, как Gucci, Nina Ricci, Kenzo',
          text4: 'В 2011 году создал грим для последних 5 эпизодов американского цикла Next Top Model 16 (Bankable Productions)',
          text5: 'С 2016 года работает консультантом по цвету в области косметических инноваций для крупнейших косметических групп',
        },
        {
            name: 'Елена Глазкова',
            country: 'Украина',
            image: Img2019_3,
            text1: 'Свадебный стилист, художник-модельер парикмахерских работ',
            text2: 'Международный инструктор-преподаватель KODI PROFESSIONAL по направлению "Парикмахерское искусство"',
            text3: 'Член судейской коллегии на Чемпионате Украины',
            text4: 'Постоянный участник fashion-показов и съёмок для журналов',
            text5: 'Опыт работы в области парикмахерского искусства более 30 лет',
        },
      ],
    },
    {
        year: 2020,
        items: [
          {
            name: 'Елена Юдина',
            country: ' Украина',
          image: Img2020_1,
          text1: 'Визажист-стилист. Мастер международного класса',
          text2: 'Бронзовый призер чемпионата мира в Лас-Вегасе (2002 г.), чемпион мира по макияжу в Милане (2004 г.)',
          text3: 'Сертифицированный судья Украины, судья международных конкурсов',
          text4: 'Основатель авторской школы макияжа и стиля Елены Юдиной VISART academy',
          text5: 'Тренер всеукраинских и международных конкурсов по макияжу и боди-арту',
          },
          {
            name: 'АТЛЫ ЕКАТЕРИНА',
            country: 'Украина',
            image: Img2020_2,
            text1: 'Международный инструктор-преподаватель УЦ Style Studio Kodi',
            text2: 'Серебряный призер Международного чемпионата по визажу в рамках фестиваля International Beauty Fest 2018',
            text3: 'ТОП-5 визажистов Международного чемпионата по визажу',
            text4: 'ТОП-10 лучших мастеров Мира по версии WBF 2019 (Австрия) в номинации Purple Brush Мakeup',
            text5: 'Стаж работы более 10 лет',
          },
          {
            name: 'Наталья Найда',
            country: 'Украина',
            image: Img2020_3,
            text1: 'Визажист международного класса',
            text2: 'Тренер и судья международных и национальных чемпионатов',
            text3: 'Основатель и руководитель Авторской школы профессионального макияжа NBschool',
            text4: 'Спикер международных обучающих проектов',
            text5: 'Автор уникальных программ и методик в сфере макияжа и декоративной косметики',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Таисия Васильева',
            country: 'Украина',
          image: Img2021_1,
          text1: 'Судья международной категории ОМС – Oragnisation Mondiale Coiffure',
          text2: 'Тренер Национальной сборной Команды Украины по макияжу и боди-арту',
          text3: 'Главный технолог по макияжу "Центра Моды - Академии парикмахерского искусства Людмилы Абрамовой"',
          text4: 'Воспитала большую плеяду учеников, победителей международных конкурсов, чемпионатов Мира и Европы',
          text5: 'Стаж работы более 20 лет',
        },
          {
            name: 'Лика Макаридзе',
            country: 'Грузия',
            image: Img2021_2,
            text1: 'Топ-5 лучших мастеров мира по визажу по версии WBF 2018',
            text2: 'Обладатель гран-при международного Чемпионата по визажу IBF 2017',
            text3: 'Международный тренер-инструктор KODI PROFESSIONAL',
            text4: 'Автор и разработчик программ по визажу',
            text5: 'Стаж работы более 15 лет',
          },
          {
            name: 'Ксения Никитина',
            country: 'Россия',
          image: Img2021_3,
          text1: 'Визажист, мастер международного класса, спикер, преподаватель по макияжу',
          text2: 'Основатель и креативный директор ICONFACE - школы профессионального макияжа, бьюти агентства',
          text3: 'Чемпион Украины (2003 г.,Киев), чемпион России (2005 г.,Москва), серебряный призёр Чемпионата Европы (2006 г., Афины)',
          text4: 'Двукратный серебряный призёр Чемпионата Мира по макияжу (2006 г., Москва, 2008 г., Чикаго)',
          text5: 'С 2015 г. выпускает линию профессиональных инструментов для визажистов',
          },
        ],
      },
      {
        year: 2022,
        items: [
          {
            name: 'Лейла Гаджиева',
            country: 'Казахстан',
            image: Img2022_1,
            text1: 'Директор по визажу в сети салонов Persona Lab в Казахстане',
            text2: 'Организатор мастер-классов и тренингов для визажистов в ведущих салонах Казахстана',
            text3: 'Автор инновационных методик в области макияжа, внедренных в салонах сети Persona Lab',
            text4: 'Участник международных выставок и семинаров в сфере визажа, с более чем 5-летним профессиональным опытом',
            text5: 'Обладатель Международной Премии “Global Beauty Leader”',
            text6: 'Победитель Международного конкурса "Звезды красоты"',
          },
          {
            name: 'Елена Юдина',
            country: ' Украина',
          image: Img2020_1,
          text1: 'Визажист-стилист. Мастер международного класса',
          text2: 'Бронзовый призер чемпионата мира в Лас-Вегасе (2002 г.), чемпион мира по макияжу в Милане (2004 г.)',
          text3: 'Сертифицированный судья Украины, судья международных конкурсов',
          text4: 'Основатель авторской школы макияжа и стиля Елены Юдиной VISART academy',
          text5: 'Тренер всеукраинских и международных конкурсов по макияжу и боди-арту',
          },
          {
            name: 'Jabe',
            country: 'Великобритания',
          image: Img2019_2,
          text1: 'Художник, визажист.',
          text2: 'Работы представлены в таких журналах, как Vogue.it, Vogue Gioiello, Vogue Accessory, Vogue Sposa',
          text3: 'Участвовал в разработке рекламных кампаний, каталогов и нескольких показов мод для таких брендов, как Gucci, Nina Ricci, Kenzo',
          text4: 'В 2011 году создал грим для последних 5 эпизодов американского цикла Next Top Model 16 (Bankable Productions)',
          text5: 'С 2016 года работает консультантом по цвету в области косметических инноваций для крупнейших косметических групп',
        },
        ],
      },
      {
        year: 2023,
        items: [
          {
            name: 'Эйнат Дан',
            country: 'Израиль',
          image: Img2023_1,
          text1: 'Один из ведущих визажистов и художников нашего времени',
          text2: 'Создатель линии кистей для макияжа MIA от Einat Dan (2012 г.) ',
          text3: 'Обладатель 28 мировых наград, включая высшие награды в индустрии визажа',
          text4: 'Работы Эйнат украшают обложки лучших журналов о моде и визаже',
          text5: 'Ее мастер-классы и воркшопы для профессиональных визажистов одними из самых востребованных в мире',
          },
          {
            name: 'Дени Санз',
            country: 'Франция',
            image: Img2023_2,
            text1: 'Художник, скульптор, визажист',
            text2: 'Основатель международной школы визажа ECOLE CHRISTIAN CHEAUVEAU в Париже 1978 году',
            text3: 'Основатель бренда MAKE UP FOR EVER в 1984 году. Преподаватель и разработчик продуктов для визажа',
            text4: 'Спикер и судья чемпионатов по визажу по всему миру',
            text5: 'Опыт более 30 лет',
          },
          {
            name: 'Яна Штепа',
            country: 'Украина',
            image: Img2019_1,
            text1: 'Судья международных чемпионатов по визажу.',
            text2: 'Автор и разработчик программ обучения в области макияжа.',
            text3: 'Визажист-стилист, гример, бровист.',
            text4: 'Инструктор-преподаватель и ведущий технолог KODI PROFESSINAL по направлению "Визаж".',
            text5: 'Визажист известных телеканалов и телепроектов.',
          },
        ],
      },
  ];

  export default data6;
